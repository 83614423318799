import styled from 'styled-components';

export const HeaderContainer = styled.div`
    height: 70px;
    width: 100%;
    background-color: #1D1D1B;
    position: fixed;
    top: 0;
    z-index: 5;

    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 3%;

    > .sit-svg {
        height: 80%;
        cursor: pointer;
        > div {
            height: 100%;
            > svg {
                height: 100%;
            }
        }
    }

    > .menu-container {
        display: none;
        align-items: center;
        column-gap: 20px;
        height: 100%;
        width: 80%;
        max-width: 700px;
        justify-content: space-between;
    }

    button{
        display: none;
    }

    .menu-svg {
        width: 40px;
     }

    @media screen and (min-width: 1024px) {
        .menu-svg{
            display: none;
        }

        .menu-container{
            display: flex;
            width: calc(50vw - 55px);
            max-width: none;
            
            a{
                font-weight: 300;
                color: #FFFFFF;
                text-decoration: none;
            }

            .selecionado{
                font-weight: 500;
            }

            .link{
                position: relative;
                a{
                    font-size: 13px;
                }
            }

            .selecionado::before {
                content: ""; 
                font-weight: 100px;
                color: #e1e1e1;
                position: absolute;
                bottom: -10px;
                width: 40px;
                height: 3px;
                background-color: #D81020;
                left: 50%; 
                transform: translateX(-50%);
                display: block;
                margin: 0 auto;
            }

            button{
                display: block;
                color: #FFFFFF;
                padding: 8px 20px;
                border: none;
                border-radius: 8px;
                font-weight: 700;
                font-size: 15px;
                cursor: pointer;
                background-color: #D81020;
            }
        }
    }

    @media(min-width: 1235px){
        .menu-container > .link{
                a{
                    font-size: 18px;
                }
            }

        button{
            font: 18px;
        }
    }
`;