import styled from "styled-components";

export const SolucoesStyle = styled.div`
    margin-top: 70px;
    overflow-x: hidden;
    
    .titulo{
        width: 100%;

        img{
            position: absolute;
            right: 0px;
            top: 80px;
            width: 80px;
            height: 80px;
        }

        h2{
            display: none;
        }

        span{
            font-weight: 300;
            font-size: 25px;
            color: #1D1D1B;
        }
    }

    h1{
        width: max-content;
        padding: 35px 0px;
        margin: 0 auto;
        font-size: 25px;
        font-weight: 300;
        color: #1D1D1B;
        justify-content: center;
        position: relative;

        strong{
            font-size: 25px;
            font-weight: 700;
            margin-left: 8px;
        }
    }

    .card{
        width: 90%;
        margin: 0 auto;
        margin-top: 20px;
        padding: 40px 30px;
        border-radius: 10px;
        background-color: rgba(116, 116, 116); 
        opacity: 1.0;
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
        position: relative;

        h3{
            font-size: 20px;
            font-weight: 300;
            color: #FFFFFF;
            margin-bottom: 20px;

            strong{
                color: #FFFFFF;
                font-size: 20px;
                font-weight: 500;
            }
        }

        p{
            color: #FFFFFF;
            font-size: 16px;
            font-weight: 300;
            line-height: 30px;
            margin-bottom: 20px;

            strong{
                color: #FFFFFF;
                font-size: 16px;
                font-weight: 500;
            }
        }

        img{
            position: absolute;
            right: 0px;
            bottom: 0px;
        }

        li{
            color: #FFFFFF;
            font-size: 16px;
            font-weight: 300;
            line-height: 23px;
            margin-bottom: 20px;
            width: 85%;
            margin: 0 auto 8px;

            strong{
                color: #FFFFFF;
                font-size: 16px;
                font-weight: 500;
            }
        }

        .lista{
            display: flex;
            flex-direction: column;
            justify-content: space-between;
        }

        .around{
            margin: 0 auto;
            display: flex;
            justify-content: space-between;
            width: 90%;

            li{
                font-size: 16px;
                font-weight: 500;
            }
        }
    }

    .setaCimaBaixo{
        display: flex;
        margin: 12px auto;
    }

    .bolaVermelha{
        position: absolute;
        left: 0px;
        top: 400px;
    }

    .bolaVermelha2{
        z-index: -1;
        position: absolute;
        right: 0px;
        top: 1200px;
    }

    .reta{
        display: none;
    }

    .retaEsquerda1,.retaEsquerda2,.retaEsquerda3,.retaDireita1,.retaDireita2, .retaBaixo{
        display: none;
    }

    @media(min-width: 425px){
        h1{
            font-size: 28px;

            strong{
                font-size: 28px;
            }

            span{
                font-weight: 300;
                font-size: 28px;
            }
        }
    }

    @media(min-width: 1024px){
        .retaEsquerda1,.retaEsquerda2,.retaEsquerda3,.retaDireita1,.retaDireita2, .retaBaixo{
            display: flex;
        }
        
        .titulo{
            width: 70%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin: 0 auto;
            h1{
                font-size: 50px;
                display: flex;
                flex-direction: column;
                width: 400px;
            }

            strong{
                font-size: 50px;
                margin-left: 0px;
            }

            span{
                font-size: 50px;
            }

            h2{
                width: 55%;
                font-size: 20px;
                display: inline;
                font-weight: 500;
                
                strong{
                    font-size: 20px;
                }
            }
        }

        .card{
            width: 400px;

            p{
                font-size: 20px;

                strong{
                    font-size: 20px;
                }
            }
        }

        .linha {
            width: 100%;
            height: max-content;
            position: relative; 
            padding-bottom: 60px;
        }

        .linha::before {
            content: "";
            width: 6px;
            height: 2428px;
            background-color: rgb(216, 16, 32);
            position: absolute;
            left: 50%;
            top: 254px;
            transform: translateX(-50%);
        }

        .esquerda{
            position: relative;
            right: 25%;
        }

        .direita{
            position: relative;
            left: 25%;
        }

        .setaCimaBaixo{
            display: none;
        }

        .retaEsquerda1{
            width: 5%;
            top: 250px;
            left: calc(50% - 5%);
            position: absolute;
        }

        .retaEsquerda2{
            width: 5%;
            top: 1235px;
            left: calc(50% - 5%);
            position: absolute;
        }

        .retaEsquerda3{
            width: 5%;
            top: 2340px;
            left: calc(50% - 5%);
            position: absolute;
        }

        .retaDireita1{
            width: 5%;
            top: 753px;
            right: calc(50% - 5%);
            position: absolute;
        }

        .retaDireita2{
            width: 5%;
            top: 1805px;
            right: calc(50% - 5%);
            position: absolute;
        }
        
        .retaBaixo{
            width: 20px;
            position: absolute;
            bottom: 0px;
            right: calc(50% - 10px);
        }
    }

    @media(min-width: 1280px){
        .card{
            width: 500px;
        }

        .linha::before {
            content: "";
            width: 7px;
            height: 2145px;
            background-color: #D81020;
            position: absolute;
            left: 50%;
            top: 207px;
            transform: translateX(-50%); 
        }

        .retaEsquerda1{
            width: 5%;
            top: 200px;
            left: calc(50% - 5% + 3px);
            position: absolute;
        }

        .retaEsquerda2{
            width: 5%;
            top: 1080px;
            left: calc(50% - 5%);
            position: absolute;
        }

        .retaEsquerda3{
            width: 5%;
            top: 2060px;
            left: calc(50% - 5%);
            position: absolute;
        }

        .retaDireita1{
            width: 5%;
            top: 640px;
            right: calc(50% - 5%);
            position: absolute;
        }

        .retaDireita2{
            width: 5%;
            top: 1540px;
            right: calc(50% - 5%);
            position: absolute;
        }
    }

    @media(min-width: 1510px){
        .linha::before {
            height: 2125px;
            top: 220px;
        }

        .retaEsquerda1{
            width: 5%;
            top: 210px;
        }

        .retaEsquerda2{
            width: 5%;
            top: 1074px;
        }

        .retaEsquerda3{
            width: 5%;
            top: 2060px;
        }

        .retaDireita1{
            width: 5%;
            top: 640px;
        }

        .retaDireita2{
            width: 5%;
            top: 1550px;
        }
    }
`