import styled from "styled-components";

export const FormStyle = styled.div`
    position: relative;
    padding: 10px 0px 40px;

    .form{
        position: relative;
        display: flex;
        flex-direction: column;
        margin: 0 auto;
        width: 80%;
        h3{        
            font-weight: 600;
            font-size: 36px;
        }

        h4{
            margin-top: 30px;
            font-weight: 300;
            font-size: 36px;
        }
    }

    .relative{
        z-index: 2;
    }

    form{
        display: flex;
        flex-direction: column;
        margin: 0 auto 40px;
        width: 100%;

        input{
            width: 100%;
            margin: 0px 0px 6px;
            padding: 10px;
            border: 1px solid #DADADA;
            border-radius: 4px;
            font-size: 12px;
        }

        select{
            margin: 0px 0px 10px;
            padding: 10px;
            border: 1px solid #DADADA;
            border-radius: 4px;
            font-size: 12px;
            width: 100%;
        }

        textarea{
            min-width: 100%;
            min-height: 60px;
            margin:  0px;
            padding: 10px;
            border: 1px solid #DADADA;
            border-radius: 4px;
            font-size: 12px;
        }

        button{
            margin: 10px 0px;
            padding: 10px;
            border: none;
            background-color: #DADADA;
            color: white;
            border-radius: 4px;
        }

        input[type='checkbox']{
            width: 16px;
            height: 16px;
            border: red 1px solid;
            margin: 0px;
        }

        button{
            display: flex;
            margin: 16px auto 20px;
            border: none;
            background-color: rgb(216, 16, 32);
            color: white;
            border-radius: 8px;
            font-size: 14px;
            padding: 5px 12px 7px 17px;
            -webkit-box-align: center;
            align-items: center;
        }
    }

    .divCheckox{
        display: flex;
        align-items: center;
        margin-top: 10px;

        label{
            font-size: 12px;
            margin-left: 10px;

            strong{
                font-size: 12px;
                font-weight: 600;
                text-decoration: underline;
            }
        }
    }

    .loc{
        display: flex;
        width: 100%;
        max-width: 600px;
        /* margin: 10px auto; */
        cursor: pointer;
        position: relative;
        z-index: 3;
    }

    .spanMapa{
        display: block;
        font-size: 16px;
        font-weight: 400;
        margin: 10px 0px;
    }

    .email{
        font-size: 16px;
        font-weight: 600;
        color: #D81020;
    }

    .conversar{
        display: flex;
        flex-direction: column;
        margin: 0 auto;
        width: 80%;

        h4{        
            margin: 30px 0px 0px;
            font-weight: 600;
            font-size: 36px;
        }

        h3{
            margin-top: 30px;
            font-weight: 300;
            font-size: 36px;
            margin: 0px;
            line-height: 0.8;
            margin-bottom: 18px;
        }
    }

    .mapa{
        display: flex;
        margin: 0 auto;
        width: 70%;
    }

    .h3Mapa{
        font-size: 24px;
        font-weight: 300;
        text-align: center;
        margin-bottom: 20px;
        width: 300px;
        margin: 20px auto 30px;

        strong{
            color: #D81020;
            font-size: 25px;
            font-weight: 700;
        }
    }

    p{
        font-size: 12px;
        color: #D81020;
    }

    .cartola{
        position: absolute;
        height: 40%;
        top: 0px;
        right: 0px;
        z-index: 0;
    }

    @media(min-width: 1024px){
        .divMapa{
            display: flex;
            align-items: center;
            justify-content: center;

            .h3Mapa{
                margin: 0px;
                margin-left: 20px;
                width: 430px;

                font-size: 40px;

                strong{
                    font-size: 40px;
                }
            }
        }

        form > button{
            font-size: 20px;
            padding: 8px 15px;
            
            img{
                width: 30px;
                height: 30px;
            }

            .setaBotao{
                width: 28px;
                height: 28px;
            }
        }

        .form{
            form{
                width: max-content;
            }

            flex-direction: row-reverse;
            justify-content: space-between;
            
            img{
                width: 100%;
            }

            input{
                width: 400px;
            }

            .relative{
                margin: 0px 0px 1px;
            }

            textarea{
                min-width: 400px;
                max-width: 400px;
            }
        }

        .divMapas{
            width: 90%;
            margin-right: 80px;

            img{
                max-width: none;
                margin: 0px;
            }
        }
    }

    .cartola{
        height: 88%;
    }
`