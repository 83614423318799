import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import ScrollToTop from '../components/ScrollToTop';
import CasesPage from '../pages/CasesPage';
import HomePage from '../pages/HomePage';
import { NossasSolucoesPage } from '../pages/NossasSolucoes';
import { SobrePage } from '../pages/SobrePage';
import { TermosUsoPage } from '../pages/TermosUsoPage';

const Router = () => {

    return (
        <BrowserRouter>
            <ScrollToTop />
            <Routes>
                <Route path='/' element={<HomePage />}/>
                <Route path='/cases' element={<CasesPage />} />
                <Route path='/solucoes' element={<NossasSolucoesPage/>} />
                <Route path='/sobre' element={<SobrePage/>} />
                <Route path='*' element={<Navigate to='/' />} />
                <Route path='/termosUso' element={<TermosUsoPage />} />
            </Routes>
        </BrowserRouter>
    )
}

export default Router;