import { SobreStyle } from "./styles"
import Todos from "../../assets/todos.svg"
import Cartola from "../../assets/cartola.svg"
import LazyLoad from 'react-lazyload';
import CaixaVermelha from "../../assets/caixa-vermelha.svg"
import CerebroVermelho from "../../assets/cerebro-vermelha.svg"
import GraficoVermelho from "../../assets/grafico-vermelho.svg"
import CalendarioVermelho from "../../assets/calendario-vermelho.png"
import LocalVermelho from "../../assets/local-vermelho.svg"
import SetaPraCima from "../../assets/seta-pra-cima.svg"
import SetaPraBaixo from "../../assets/seta-pra-baixo.svg"
import SetaPraCimaBaixo from "../../assets/seta-cima-baixo.svg"
import Estrela from "../../assets/estrela.svg"
import Virgula from "../../assets/vrigula.png"
import EstrelaEsquerda from "../../assets/estrelaEsquerda.png"

export const Sobre = () => {
    return(
        <SobreStyle>
            <h1>Por que uma
                <strong>
                    {" cartola"}
                </strong>
                ?
                    <img src={Cartola} alt="cartola"/>
            </h1>
            <div className="top">
                <img className="todos" src={"https://istockblobs.blob.core.windows.net/sitesittrade/todos.webp"} alt="todos"/>
                <div className="column">
                    <h1 className="h1Grande">Por que uma
                            <strong>
                                {" cartola"}
                            </strong>
                            ?
                        </h1>
                    <h2 className="descricao"> 
                        A SIT nasceu do desejo visionário de solucionar 
                        a dificuldade de comunicação que existia entre as 
                        <strong>
                            {" diversas demandas que um único cliente pode ter"}
                        </strong>
                            {" e a"}
                        <strong>
                            {" grande variedade de fornecedores de serviços específicos "}
                        </strong>
                        do setor de trade marketing.
                    </h2>
                    <h2 className="descricao margin-top">
                        Visando solucionar e facilitar o mercado, viemos para ser uma empresa que
                        <strong>
                            {" atende a toda e qualquer necessidade de um cliente no âmbito do trade."}
                        </strong>
                    </h2>
                    <LazyLoad>
                    <img src={Cartola} alt="cartola" className="cartola"/>
                </LazyLoad>
                </div>
            </div>
            <h3>
                Nossa
                <strong>
                    {" linha do tempo"}
                 </strong>
            </h3>
            <div className="fluxo">
                <div className="parte">
                    <LazyLoad>
                        <img src={Estrela} className="estrelaCinza"/>
                    </LazyLoad>
                    <LazyLoad>
                        <img src={CaixaVermelha} className="icone-vermelha"/>
                        <img src={SetaPraCimaBaixo} className="setaCimaBaixo" alt="setaCimaBaixo"/>
                    </LazyLoad>
                    <LazyLoad>
                        <img src={SetaPraBaixo} className="seta" alt="seta"/>
                    </LazyLoad>
                    <h4>
                        Em 2018, começamos com um 
                        <strong>
                            {" time de produção"}
                        </strong>
                            {" dedicado e capaz de produzir"} 
                        <strong>
                            {" todos os tipos de materiais. "}
                        </strong>
                        No entanto, produzir era apenas uma parte da equação. Tinha que ser mais.
                    </h4>
                </div>
                <div className="parte">
                    <LazyLoad>
                        <img src={SetaPraCima} className="seta" alt="seta"/>
                    </LazyLoad>
                    <LazyLoad>
                        <img src={CerebroVermelho} className="icone-vermelha"/>
                        <img src={SetaPraCimaBaixo} className="setaCimaBaixo" alt="setaCimaBaixo"/>
                    </LazyLoad>
                    <LazyLoad>
                        <img src={SetaPraBaixo} className="seta" alt="seta"/>
                    </LazyLoad>
                    <h4>
                        O próximo passo foi montar o 
                        <strong>
                            {" time de Criação"}
                        </strong>
                        para que não somente tirássemos as ideias do papel, 
                        mas também as criássemos com um time que reúne talentos
                        do design gráfico, 3D e projetistas.
                    </h4>
                </div>
                <div className="parte">
                    <LazyLoad>
                        <img src={SetaPraCima} className="seta" alt="seta"/>
                    </LazyLoad>
                    <LazyLoad>
                        <img src={GraficoVermelho} className="icone-vermelha"/>
                        <img src={SetaPraCimaBaixo} className="setaCimaBaixo" alt="setaCimaBaixo"/>
                    </LazyLoad>
                    <LazyLoad>
                        <img src={SetaPraBaixo} className="seta" alt="seta"/>
                    </LazyLoad>
                    <h4>
                        Depois de garantir a materialização de ideias, precisávamos 
                        <strong>
                            {" de um agente transformador de performance."} 
                        </strong>
                            {" Criamos então um"}
                        <strong>
                            {" time expert em Tecnologia para o trade,  "}
                            
                        </strong> 
                        que eleva e aprimora toda solução do mercado à potência máxima
                    </h4>
                </div>
                <div className="parte">
                    <LazyLoad>
                        <img src={SetaPraCima} className="seta" alt="seta"/>
                    </LazyLoad>
                    <LazyLoad>
                        <img src={CalendarioVermelho} className="icone-vermelha" alt="Calendário Vermelho"/>
                        <img src={SetaPraCimaBaixo} className="setaCimaBaixo" alt="setaCimaBaixo"/>
                    </LazyLoad>
                    <LazyLoad>
                        <img src={SetaPraBaixo} className="seta" alt="seta"/>
                    </LazyLoad>
                    <h4>
                    Com todas as frentes de materiais já cobertas, 
                    entendemos que as necessidades dos nossos clientes 
                    iam além: eles precisavam de 
                        <strong>
                            {" estratégia. "}
                        </strong>
                    Nasceu então o 
                        <strong>
                            {" time de Planejamento."}
                        </strong>
                    </h4>
                </div>
                <div className="parte">
                    <LazyLoad>
                        <img src={SetaPraCima} className="seta" alt="seta"/>
                    </LazyLoad>
                    <LazyLoad>
                        <img src={LocalVermelho} className="icone-vermelha" alt="Local Vermelho"/>
                    </LazyLoad>
                    <LazyLoad>
                        <img src={SetaPraBaixo} className="seta" alt="seta"/>
                    </LazyLoad>
                    <h4>
                        Para fechar o ciclo, faltava apenas uma etapa:
                        os materiais saírem da SIT e chegarem ao destino
                        desejado pelo cliente. Foi então a vez da 
                        <strong>
                            {" Logística "}
                        </strong>
                        surgir, para armazenar, receber, enviar e positivar materiais.
                    </h4>
                </div>
                <LazyLoad>
                    <img src={SetaPraCima} className="seta" alt="seta"/>
                </LazyLoad>
                <h4 className="marginTop50">
                    Graças a essa reunião de expertises, nos tornamos o que lá atrás sonhávamos: um 
                    <strong>
                        {" centro de soluções integradas de trade marketing"}
                    </strong>
                    , o parceiro ideal de negócios, que detém todos os recursos e faz qualquer coisa acontecer.
                </h4>
                <h4 className="marginTop20">
                    Magos, como se tirássemos todas as soluções de uma cartola, 
                    <strong>
                        {" e por isso uma cartola."}
                    </strong>
                </h4>
                <LazyLoad>
                    <img src={EstrelaEsquerda} className="estrelaEsquerda" alt="estrelaEsquerda"/>
                </LazyLoad>
            </div>
            <section className="manifesto">
                <div className="width80">
                    <h2>MANIFESTO</h2>
                    <h5>
                        A todas as empresas que buscam desenvolver seu trade marketing: nós estamos aqui para vocês.
                    </h5>
                    <h5>
                        A todos que entendem o valor desse investimento, que entendem a importância de se fazer presente para o público,
                    </h5>
                    <h6>
                        que buscam criar conexões desde o ponto de venda, 
                    </h6>
                    <h6>
                        que querem inovar a maneira de manifestar sua marca,
                    </h6>
                    <h6>
                        que anseiam por passar mais que uma simples mensagem,
                    </h6>
                    <h6>
                        que sabem o poder da comunicação
                    </h6>
                    <h6>
                        nós somos a sua maior parceira.
                    </h6>
                    <h5 className="marginTop10">
                        Continuem firmes na busca por fazer diferente que nós continuaremos tirando sonhos da cartola.
                    </h5>
                    <h5 className="semMargin">
                        Esse é o nosso compromisso com vocês.
                    </h5>
                    <h5>
                        <strong>
                            Se vocês têm um desafio, nós temos a solução.
                        </strong>
                    </h5>
                </div>
                <LazyLoad>
                    <img src={Virgula} className="virgula" alt="virgula"/>
                </LazyLoad>
            </section>
        </SobreStyle>
    )
}