import { useEffect, useState } from "react";
import { PortifolioStyle } from "./styles";
import PressKit from "../../assets/pressKit.svg";
import Ricca from "../../assets/ricca.svg";
import Eventos from "../../assets/eventos.svg";
import Brindes from "../../assets/brindes.svg";
import Visual from "../../assets/visual.svg";
import Material from "../../assets/folha.svg";
import Tailor from "../../assets/gatorate.svg";
import Kit from "../../assets/kit.svg"
import Direita from "../../assets/direita.png";
import Esquerda from "../../assets/esquerda.png";
import SetaBotao from "../../assets/setaBotao.svg";
import { useNavigate } from "react-router-dom";


export const Portifolio = () => {
    const navigate = useNavigate();
    const [activeIndex, setActiveIndex] = useState(0);
    const [imgSelecionada, setImgSelecionada] = useState(2);
    const [direction, setDirection] = useState("right"); 
    const images = [Material, Visual, Ricca, Brindes, Tailor, Eventos, Kit];
    const nomes = ["Materiais Gráficos", "Comunicação Visual", "PressKit", "Brindes", "Tailor Mades", "Ativações e Eventos", "PressKit"];

    useEffect(() => {
        const interval = setInterval(() => {
            setDirection("right");
            setActiveIndex((prevIndex) => (prevIndex + 1) % images.length);
        }, 8000);

        return () => clearInterval(interval);
    }, [activeIndex]);

    const handlePrev = () => {
        setDirection("left");
        setImgSelecionada((prevIndex) => (prevIndex === 0 ? images.length - 1 : prevIndex - 1));

        setTimeout(() => {
            animacaoParaEsquerda();
        }, 1)
    };

    const handleNext = () => {
        setDirection("right");
        setImgSelecionada((prevIndex) => (prevIndex + 1) % images.length);

        setTimeout(() => {
            animacaoParaDireita();
        }, 1)
    };

    useEffect(() => {
        const imgEsquerda = document.getElementById("esquerda");
        const imgDireita = document.getElementById("direita");

        if (imgEsquerda) {
            imgEsquerda.classList.remove("imgEsquerda")

            setTimeout(() =>{
                imgEsquerda.classList.add("imgEsquerda");
            }, 800)
        }

        if(imgDireita){
            imgDireita.classList.remove("imgDireita")

            setTimeout(() =>{
                imgDireita.classList.add("imgDireita");
            }, 800)
        }


    }, [imgSelecionada]);

    const animacaoParaDireita = () => {
        const imgCentro = document.getElementById("centro");
        const setaDireita = document.getElementById("setaDireita");
        const setaEsquerda = document.getElementById("setaEsquerda");

        if(!imgCentro){
            return
        }

        imgCentro.classList.add("animacaoDireitaCentro");

        setTimeout(() => {
            imgCentro.classList.remove("animacaoEsquerdaCentro");
            imgCentro.classList.remove("animacaoDireitaCentro");
        }, 500);

        if(setaDireita){
            setaDireita.classList.add("animacaoSetaParaDireita");

            setTimeout(() => {
                setaDireita.classList.remove("animacaoSetaParaDireita");
            }, 500);
        }

        if(setaEsquerda){
            setaEsquerda.classList.add("animacaoSetaParaEsquerda");

            setTimeout(() => {
                setaEsquerda.classList.remove("animacaoSetaParaEsquerda");
            }, 500);
        }
    }

    const animacaoParaEsquerda = () => {
        const imgCentro = document.getElementById("centro");
        const setaDireita = document.getElementById("setaDireita");

        if(!imgCentro){
            return
        }

        imgCentro.classList.add("animacaoEsquerdaCentro");
        setTimeout(() => {
            imgCentro.classList.remove("animacaoEsquerdaCentro");
            imgCentro.classList.remove("animacaoDireitaCentro");
        }, 500);

        if(setaDireita){
            setaDireita.classList.add("animacaoSetaParaDireita");

            setTimeout(() => {
                setaDireita.classList.remove("animacaoSetaParaDireita");
            }, 500);
        }
    }

    return (
        <PortifolioStyle>
            <h2>Port<strong>fólio</strong></h2>
            <div className="lista">
                <ol>
                    <li className={activeIndex === 0 ? "selecionado" : ""} onClick={() => setActiveIndex(0)}>Materiais Gráficos</li>
                    <li className={activeIndex === 1 ? "selecionado" : ""} onClick={() => setActiveIndex(1)}>Comunicação Visual</li>
                    <li className={activeIndex === 2 ? "selecionado" : ""} onClick={() => setActiveIndex(2)}>Displays</li>
                    <li className={activeIndex === 3 ? "selecionado" : ""} onClick={() => setActiveIndex(3)}>Brindes</li>
                    <li className={activeIndex === 4 ? "selecionado" : ""} onClick={() => setActiveIndex(4)}>Tailor Mades</li>
                    <li className={activeIndex === 5 ? "selecionado" : ""} onClick={() => setActiveIndex(5)}>Ativações e Eventos</li>
                    <li className={activeIndex === 6 ? "selecionado" : ""} onClick={() => setActiveIndex(6)}>PressKit</li>
                    <button className="conhecaNossosCases" onClick={() => navigate("/cases")}>conheça nossos cases
                        <img src={SetaBotao} className="setaBotao"/>
                    </button>
                </ol>
                {images.map((image, index) => (
                    <img
                        key={index}
                        src={image}
                        alt="portifolio item"
                        className={activeIndex === index ? `imagemSelecionada ${direction}` : ""}
                    />
                ))}
            </div>
            <div className="listaMobile">
                <div className="relative body">
                    {imgSelecionada !== 0 && <img src={Esquerda} id="setaEsquerda" className="seta-esquerda-mobile" onClick={() => {
                        handlePrev()
                    }}/>}
                    {imgSelecionada > 0 && <img src={images[imgSelecionada - 1]} id="esquerda" alt="prev" className={`imgEsquerda`} onClick={() => {
                        handlePrev()
                    }}/>}
                    <img src={images[imgSelecionada]} alt="current" id="centro" className={`imgPortifolio centro ${direction} `} />
                    {imgSelecionada < 6 && <img src={images[imgSelecionada + 1]} id="direita" alt="next" className="imgDireita" onClick={() => {
                    handleNext()
                    }}/>}
                    {imgSelecionada !== 6 && <img src={Direita} alt="seta direita" id="setaDireita"  className="seta-direita-mobile" onClick={() => {
                    handleNext()
                    }}/>}
                </div>
                {
                    nomes[imgSelecionada] === "Materiais Gráficos" ? <h5 className="nome0">Materiais Gráficos</h5> :    
                    nomes[imgSelecionada] === "Comunicação Visual" ? <h5 className="nome1">Comunicação Visual</h5> :
                    nomes[imgSelecionada] === "PressKit" ? <h5 className="nome2">PressKit</h5> :
                    nomes[imgSelecionada] === "Brindes" ? <h5 className="nome3">Brindes</h5> :
                    nomes[imgSelecionada] === "Tailor Mades" ? <h5 className="nome3">Tailor Mades</h5> :
                    nomes[imgSelecionada] === "Ativações e Eventos" ? <h5 className="nome4">Ativações e Eventos</h5> :
                    nomes[imgSelecionada] === "Displays" ? <h5 className="nome5">Displays</h5> : ""
                }
                <button className="saibaMais" onClick={
                    () => navigate("/cases")
                }>conheça nossos cases
                    <img src={SetaBotao} className="setaBotao"/>
                </button>
            </div>
        </PortifolioStyle>
    );
};
