import { SolucoesStyle } from "./styles"
import Cartola from "../../assets/cartola.svg"
import LazyLoad from "react-lazyload"
import LetraO from "../../assets/letra-o.svg"
import SetaCimaBaixo from "../../assets/seta-cima-baixo.svg"
import BolaVermelha from "../../assets/bola-vermelha.svg"
import BolaVermelha2 from "../../assets/bola-vermelha2.svg"
import RetaEsquerda from "../../assets/reta-esquerda.svg"
import RetaDireita from "../../assets/reta-direita.svg"
import RetaBaixo from "../../assets/seta-pra-cima.svg"
import { Form } from "../Form"

export const Solucoes = () => {
    return(
        <SolucoesStyle>
            <img src={BolaVermelha} className="bolaVermelha" alt="Bola Vermelho"/>
            <div className="titulo">
                <h1>Como fazemos
                    <strong>
                        {"acontecer "} 
                        <span>
                            ?
                        </span>
                    </strong>
                </h1>
                <h2>
                    <strong>
                        {` Reunimos todas as expertises necessárias para 
                        atender às demandas de uma campanha de trade marketing. `}
                    </strong>
                        Podemos atuar em todas as etapas ou de maneira isolada, sempre de acordo com a sua necessidade. 
                </h2>
                <LazyLoad>
                        <img src={Cartola} alt="cartola"/>
                </LazyLoad>
            </div>
            <div className="linha">
            <div className="card esquerda">
                <h3>
                    Planeja
                    <strong>mento</strong>
                </h3>
                <p>
                    O time de Planejamento 
                    <strong>
                    {" estabelece objetivos, mapeia o público-alvo, cria estratégias e define métricas "}
                    </strong> 
                    para os projetos dos nossos clientes, montando o plano de campanha por inteiro.
                </p>
                <p>
                    Nossa prioridade é 
                    <strong>
                        {" aumentar a efetividade das campanhas "}
                    </strong>
                    através dos fundamentos e atualidades do marketing e da publicidade no nosso mercado.
                </p>
                <LazyLoad>
                    <img src={LetraO} alt="o"/>
                </LazyLoad>
            </div>
            <img src={SetaCimaBaixo} className="setaCimaBaixo" alt="setaCimaBaixo"/>
            <div className="card direita">
                <h3>
                    Cri
                    <strong>ação</strong>
                </h3>
                <p>
                    Nossa equipe criativa reúne profissionais de áreas diferentes e complementares do design para a
                    <strong>
                    {" idealização de projetos plenos e disruptivos. "}
                    </strong> 
                </p>
                <p>
                    Compreendemos o cliente e o mercado para
                    <strong>
                        {" criar, inovar e transformar "}
                    </strong>
                    cada vez mais o cenário do trade através de um compromisso constante com o “pensar fora da caixa”.
                </p>
                <LazyLoad>
                    <img src={LetraO} alt="o"/>
                </LazyLoad>
                <LazyLoad>

                </LazyLoad>
            </div>
            <img src={SetaCimaBaixo} className="setaCimaBaixo" alt="setaCimaBaixo"/>
            <div className="card esquerda">
                <h3>
                    Prod
                    <strong>ução</strong>
                </h3>
                <p>
                    Nosso time de produção tira todas as ideias do papel, desenvolvendo produtos com o conhecimento 
                    técnico que torna projetos criativos em realidade tangível.
                </p>
                <p>
                    Tendo como base a 
                    <strong>
                        expertise e agilidade, 
                    </strong>
                    produzimos todas as frentes de materiais com o
                    <strong>
                        {" melhor prazo de execução do mercado. "}
                    </strong>
                </p>
                <LazyLoad>
                    <img src={LetraO} alt="o"/>
                </LazyLoad>
            </div>
            <img src={SetaCimaBaixo} className="setaCimaBaixo" alt="setaCimaBaixo"/>
            <div className="card direita">
                <h3>
                    Gestão e Tecno
                    <strong>logia</strong>
                </h3>
                <p>
                    Com nossa área especializada em
                    <strong>
                        {" tecnologia para o trade "}
                    </strong>
                    ajudamos nossos clientes a construir campanhas de alto impacto atuando em duas frentes:
                </p>
                <ul>
                    <li>
                        <strong>
                            {"Digital Experience: "}
                        </strong>
                        elevamos a evidência do 
                        <strong>
                            {" seu MPDV "} 
                        </strong>
                        através do uso de telas digitais que trazem uma 
                        abordagem dinâmica e moderna com o shopper, impactando positivamente no share of mind da sua marca.
                    </li>
                    <li>
                        <strong>
                            {"iManage: "}
                        </strong>
                        nossa plataforma integrada permite a  
                        <strong>
                            {" otimização de suas campanhas de MPDV, gerando controle, dados e visibilidade"}
                        </strong>
                        em tempo real dos seus ativos. Entregamos a gestão do seu projeto na palma de sua mão.
                    </li>
                </ul>
            </div>
            <img src={SetaCimaBaixo} className="setaCimaBaixo" alt="setaCimaBaixo"/>
            <div className="card esquerda">
                <h3>
                    Logí
                    <strong>stica</strong>
                </h3>
                <p>
                Nossa logística fornece gestão e rastreamento em tempo real dos ativos de nossos clientes
                e os transporta para 
                    <strong>
                        {" todo "}
                    </strong> o 
                    <strong>
                        {" Brasil "}
                    </strong>
                    , através de modais fracionados, dedicados e aéreos.
                </p>
                <div className="around"> 
                    <ul className="lista">
                        <li>
                            Recebimento 
                        </li>
                        <li>
                            Armazenagem
                        </li>
                        <li>
                            Transporte
                        </li>
                        <li>
                            Manuseio
                        </li>
                        <li>
                            Gerenciamento
                        </li>
                        <li>
                            Fiscal
                        </li>
                    </ul>
                    <ul>
                        <li>
                            Embalagem
                        </li>
                        <li>
                            Rastreamento
                        </li>
                        <li>
                            Ativação PDV
                        </li>
                        <li>
                            Follow Up.
                        </li>
                    </ul>
                </div>
                <LazyLoad>
                    <img src={LetraO} alt="o"/>
                </LazyLoad>
            </div>
            <img src={BolaVermelha2} className="bolaVermelha2" alt="Bola Vermelho"/>
            <img src={RetaEsquerda} className="retaEsquerda1" alt="Reta Esquerda1"/>
            <img src={RetaEsquerda} className="retaEsquerda2" alt="Reta Esquerda2"/>
            <img src={RetaEsquerda} className="retaEsquerda3" alt="Reta Esquerda3"/>
            <img src={RetaDireita} className="retaDireita1" alt="Reta Direita1"/>
            <img src={RetaDireita} className="retaDireita2" alt="Reta Direita2"/>
            <img src={RetaBaixo} className="retaBaixo" alt="Reta Baixo"/>
        </div>
        <Form/>
        </SolucoesStyle>
    )
}