import styled from 'styled-components';

export const CasesContainer = styled.div`
    padding: 20px 0;
    width: 100%;
    margin: 60px auto 0;

    .titulo{
        width: 100%;

        img{
            position: absolute;
            top: 90px;
            right: 0px;
            width: 80px;
            height: 80px;
        }
    }

    h1{
        width: 300px;
        text-align: center;
        padding: 35px 0px;
        margin: 0 auto;
        font-size: 22px;
        font-weight: 300;
        color: #1D1D1B;
        justify-content: center;
        position: relative;

        strong{
            font-size: 22px;
            font-weight: 700;
            margin-left: 8px;
        }
    }

    .divMikes{
        position: relative;
    }

    .mikes{
            margin: 40px 0px 38px;
            width: 100%;
            height: max-content;
    }

    .tag{
        position: absolute;
        left: 10px;
        bottom: 50px;
        border-radius: 8px;
        background-color: #1D1D1B;

        font-size: 14px;
        padding: 14px 10px;
        color: #FFFFFF;
        
        strong{
            font-size: 14px;
            font-weight: 500;
            color: #FFFFFF;
        }
    }

    h3{
        font-size: 16px;
        font-weight: 300;
        width: 90%;
        margin: 0 auto;
        margin-top: 20px;
        line-height: 23px;
        color: #1D1D1B;

        strong{
            font-size: 16px;
            font-weight: 500;
            color: #1D1D1B;
        }
    }

    .video-container {
    position: relative;
    display: inline-block;
    width: 640px; /* Defina o tamanho desejado do vídeo */
    max-width: 100%;
    }

    video {
        width: 100%;
        margin: 30px 0px;
    }

    .whiskas-video {
        width: 100px;
    }

    .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background: rgba(0, 0, 0, 0.5); /* Deixa o fundo um pouco transparente */
    color: white;
    }

    .play-button {
    background-color: transparent;
    border: none;
    cursor: pointer;
    text-align: center;
    }

    .play-button span {
    width: max-content;
    margin: 0 auto;
    font-size: 1.5rem;
    background-color: rgba(255, 0, 0, 0.75);
    padding: 10px 15px;
    border-radius: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    }

    .play-button span::before {
    content: "▶"; /* Adiciona o ícone de play */
    font-size: 2rem;
    color: #e1e1e1;
    }

    .video-info {
    margin-top: 20px;
    }

    .button-o {
    background-color: #000000c0;
    color: white;
    border: none;
    padding: 10px 20px;
    font-size: 1.2rem;
    border-radius: 50px;
    cursor: pointer;
    }

    .button-o strong {
    color: #e1e1e1;
    }

    .relative{
        position: relative;  
    }

    .play{
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        font-size: 40px;
        color: #FFFFFF;
        padding: 6px 16px 8px 20px;
        background-color: #D81020;
        border-radius: 100px;
        cursor: pointer;
    }

    h6{
        position: absolute;
        top: 67%;
        left: 50%;
        transform: translate(-50%, -50%);
        font-size: 16px;
        font-weight: 500;
        color: #FFFFFF;
        padding: 8px 12px;
        background-color: #1D1D1B;
        border-radius: 8px;
        cursor: pointer;
    }

    .whiskas-video {
        width: 100%;
        height: 100vh; /* Ocupa a altura total da tela */
        object-fit: contain; /* Mantém a proporção original do vídeo */
        background-color: black; /* Adiciona fundo preto para as barras laterais */
    }


    @media(min-width: 900px){
       .textos{
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;

            display: grid;
            grid-template-columns: repeat(2, 1fr);

            h3{
                width: 90%;
                font-size: 18px;

                strong{
                    font-size: 18px;
                }
            }
       }

       .padding{
              padding: 42px 0px;
       }

       .marginNegativa{
        margin: -42px auto 0px;
       }

       h1{
            font-size: 50px;
            width: 500px;

            strong{
                font-size: 50px;
            }
       }

       .tag{
        width: 300px;
        font-size: 20px;
        font-weight: 300;

        strong{
            font-size: 20px;
        }
    }


    }

    .titulo img{
        width: 250px;
        height: 250px;
    }

    .marginNegativa20{
        margin: -10px auto 0px;
    }
`;