import styled from 'styled-components';

export const FooterContainer = styled.section`
    width: 100%;
    background-color: #D1D1D1;

    .body{
        width: 80%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 0 auto;
        padding: 10px 0px;
    }

    .redeSocial{
        width: 25px;
        margin-right: 10px;
    }

    .column{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        span{
            font-size: 12px;
        }
    }

    .rodaPe{
        width: 185px;
    }

    .fim{
        width: 100%;
        background-color: #1D1D1B;
        padding: 10px 0px;
        text-align: center;

        span{
            color: #FFFFFF;
        }
    }

    @media(min-width: 768px){
        .redeSocial{
            width: 30px;
            margin-right: 10px;
        }
    }
`;