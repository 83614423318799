import { HomeContainer } from './styles';
import Infografico from "../../assets/infografico.svg";
import Mapa from "../../assets/mapa.svg";
import { Portifolio } from '../Portifolio';
import Marcas from "../../assets/marcas.svg";
import { useNavigate } from 'react-router-dom';
import { Form } from '../Form';
import 'react-toastify/dist/ReactToastify.css';
import { useState } from 'react';
import { useEffect } from 'react';
import SetaBotao from "../../assets/setaBotao.svg";
import Mikes from "../../assets/balde-mikes.webp";
import Gillete from "../../assets/gillete.webp";
import MilkShake from "../../assets/milkshake.webp";
import Spaten from "../../assets/becks.webp";
import Luta from "../../assets/spaten.webp";
import Ricca from "../../assets/ricca.webp";
import { ReactSVG } from 'react-svg';

const Home = () => {
    const navigate = useNavigate();
    const images = [
        "https://istockblobs.blob.core.windows.net/sitesittrade/balde-mikes.webp", 
        "https://istockblobs.blob.core.windows.net/sitesittrade/gillete.webp",
        "https://istockblobs.blob.core.windows.net/sitesittrade/milkshake.webp",
        "https://istockblobs.blob.core.windows.net/sitesittrade/becks.webp",
        "https://istockblobs.blob.core.windows.net/sitesittrade/spaten.webp",
        "https://istockblobs.blob.core.windows.net/sitesittrade/ricca.webp"
    ];

    const [activeIndex, setActiveIndex] = useState(0);

    useEffect(() => {
        const interval = setInterval(() => {
            // quero remover o className de todas as imagens
            const imagens = document.getElementById('opacity');
            if(imagens){
                imagens.classList.remove('loaded');
                setTimeout(() => {
                    setActiveIndex((prevIndex) => (prevIndex + 1) % images.length);
                    imagens.classList.add('loaded');
                }, 100)
            }
        }, 5000);

        return () => clearInterval(interval);
    }, [activeIndex]);

    return (
        <HomeContainer>
            <div className='top'>
                <h1>Somos especialistas em soluções integradas de 
                    <strong>{" Trade Marketing"}</strong>
                    <button className='saibaMais' onClick={() => {
                        const elementoClicado = document.getElementById('grafico');
                        if (elementoClicado) {
                            elementoClicado.scrollIntoView({
                                behavior: "smooth",
                                block: "center",
                            });
                        }
                    }}>
                        saiba mais
                        <img src={SetaBotao} className='setaBotao'/>
                    </button>
            </h1>
            <div className='desktop'>
                <div className='vh50'>
                </div>
                {images.map((image, index) => (
                    <img
                        key={index}
                        src={image}
                        alt="portifolio item"
                        className={activeIndex === index ? `imagemSelecionada` : ""}
                    />
                ))}
            </div>
            <div className="">
                <img id='opacity' src={images[activeIndex]} className={`loaded`}/>
            </div>
            </div>
            <div className='grafico' id="grafico">
                <ReactSVG className='infografico' src={Infografico}/>
                <div className='producoes'>
                    <h2>Prod
                        <strong>uções</strong>
                    </h2>
                <div className='flex'>
                    <ul className='marginRight35'>
                        <li>Design 3D</li>
                        <li>Design Gráfico</li>
                        <li>MPDV Design</li>
                        <li>Retail Design</li>
                        <li>Planejamento estratégico</li>
                        <li>Brindes personalizados</li>
                        <li>Comunicação Visual</li>
                        <li>Material promocional</li>
                        <li>Press Kit</li>
                        <li>Sales Kit</li>
                    </ul>
                    <ul>
                        <li>Gestão Real Time</li>
                        <li>Eventos</li>
                        <li>Promotoria</li>
                        <li>Ativações de marca</li>
                        <li>Projetos Tailor Made</li>
                        <li>Embalagem</li>
                        <li>Digital Signage</li>
                        <li>Displays</li>
                        <li>AudioVisual</li>
                    </ul>
                </div>
                <button onClick={() => navigate("/solucoes")} className='conhecaMais'>
                    conheça mais
                    <img src={SetaBotao} className='setaBotao'/>
                </button>
                </div>
            </div>
            <div className='divMapa'>
                <img className='mapa' src={Mapa}/>
                <h3 className='h3Mapa'>Estamos presentes em todo 
                    <strong> Brasil</strong>
                        {" " + "e" + " "}
                    <strong>México</strong>.
                </h3>
            </div>
            <Portifolio/>
            <div className='divParceiros'>
                <h2>Alguns clientes que temos orgulho de ser <strong>parceiros</strong></h2>
                    <div class="carousel">
                    <div class="carousel-content">
                    <img src={Marcas} className='marcas'/>
                    <img src={Marcas} className='marcas'/>
                    <img src={Marcas} className='marcas'/>
                    <img src={Marcas} className='marcas'/>
                    <img src={Marcas} className='marcas'/>
                    <img src={Marcas} className='marcas'/>
                    <img src={Marcas} className='marcas'/>
                    </div>
                </div>
            </div>
            <Form/>
        </HomeContainer>
    );
};

export default Home;