import styled from 'styled-components';

export const HomeContainer = styled.div`
    margin-top: 70px;

    h1{
        padding: 20px 0px;
        margin: 0 auto;
        width: 95%;
        max-width: 270px;
        font-size: 25px;
        font-weight: 400;
        text-align: center;
        strong{
            font-size: 24px;
            font-weight: 700;
        }
    }

    .grafico{
        background-color: #D9D9D961;
    }

    .saibaMais{
        display: flex;
        margin: 0 auto;
        border: none;
        background-color: #D81020;
        color: white;
        border-radius: 8px;
        font-size: 14px;
        padding: 5px 12px 7px 17px;
        margin-bottom: 20px;
        align-items: center;
        margin-top: 3px;

        img{
            width: 30px;
            height: 30px;
            position: unset;
            animation: 1s none;
            opacity: 1;
        }
    }

    .marginRight35{
        margin-right: 35px;
    }

    .mikes{
        width: 100%;
    }

    .infografico{
        svg{
            display: flex;
            margin: 0 auto 40px;
            width: 90%;
            height: 100%;
        }
    }

    .desktop{
        display: none;
    }

    .marcas{
        height: 220px;
    }

    .producoes{
        display: flex;
        flex-direction: column;
        margin: 0 auto;
        width: 78%;

        button{
            display: flex;
            margin: 20px auto;
            width: max-content;
        }

        h2{
            font-size: 25px;
            font-weight: 300;
            width: max-content;
            margin-bottom: 20px;

            strong{
                font-size: 25px;
                font-weight: 700;
            }
        }

        .marginBottom{
            margin-bottom: 20px;
        }

        .flex{
            justify-content: space-between;
        }

        ul{
            list-style: none;
        }
    }

    .grafico > li{
        font-size: 16px;
    }

    .producoes > .conhecaMais{
        display: flex;
        margin: 3px auto 20px;
        border: none;
        background-color: rgb(216, 16, 32);
        color: white;
        border-radius: 8px;
        font-size: 14px;
        padding: 5px 12px 7px 17px;
        -webkit-box-align: center;
        align-items: center;
        
        img{
            width: 30px;
            height: 30px;
            position: unset;
            animation: 1s none;
            opacity: 1;
        }
    }

    .setaBotao{
        width: 20px;
        height: 20px;
        position: unset;
        animation: none;
    }

    .vh50{
            display: none;
        }

    .divParceiros{

        background-color: #D81020;
        h2{
            width: 230px;
            text-align: center;
            font-size: 27px;
            font-weight: 300;
            line-height: 28px;
            margin: 0 auto;
            color: #FFFFFF;
            padding: 30px 0px 0px;

            strong{
                font-size: 27px;
                color: #FFFFFF;
                font-weight: 600;
            }
        }
    }

    .carousel {
        width: 100%; 
        overflow: hidden; 
    }

    .carousel-content {
        width: 100%;
        display: flex;
        animation: slide 25s linear infinite; 
    }

    .carousel-content img {
        width: auto;
        height: 180px;
        flex-shrink: 0; 
    }

    .divMapa{
            display: flex;
            flex-direction: column;
            margin: 0 auto;
            align-items: center;
        }

        @keyframes slide {
        0% {
            transform: translateX(0);
        }
        100% {
            transform: translateX(-100%);
        }
        }

    .h3Mapa{
        font-size: 24px;
        font-weight: 300;
        text-align: center;
        margin-bottom: 20px;
        width: 300px;
        margin: 20px auto 30px;

        strong{
            color: #D81020;
            font-size: 25px;
            font-weight: 700;
        }
    }

    .hw50{
        display: flex;
        width: 100%;
        height: 100%;
    }

    .top{
        img{
            width: 100%;
            height: calc(100vh - 260px); ;
            object-fit: cover;
            opacity: 0.3; /* Inicialmente invisível */
        }
    }

    img.loaded {
        opacity: 1; 
        animation: normal 1s opacity-low; 
    }

    @keyframes opacity-low {
        0% {
            opacity: 0.5;
        }
        100% {
            opacity: 1;
        }
    }



    @media(min-width: 1024px){
        .desktop{
            display: flex;
        }

        .divParceiros{
            img{
                height: 300px;
            }
        }

        .vh50{
            display: flex;
            width: 50vw;
            height: 100vh;
        }

        .divMapa{
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: row;

            .h3Mapa{
                margin: 0px;
                margin-left: 20px;
                width: 430px;

                font-size: 40px;

                strong{
                    font-size: 40px;
                }
            }

            .mapa{
                    width: 35%;
                    height: 100%;
                    margin: 0px;
            }
        }

        .top{
            display: flex;
            align-items: center;
            position: relative;
            height:calc(100vh - 70px);

            img{
                position: absolute;
                height: calc(100vh - 70px); 
                width: 50vw;   
                object-fit: cover;
                opacity: 0; 
                transition: opacity 0.5s ease-in-out; 
                top: 0;
                right: 0;
            }
        }

        .imagemSelecionada{
            display: flex !important;
            opacity: 1 !important;
            transition: opacity 0.5s ease-in-out !important; 
        }

        @keyframes opacity-low {
            0% {
                opacity: 0;
            }
            100% {
                opacity: 1;
            }
        }

        h1{
            font-size: 45px;
            width: 35%;
            text-align: left;
            max-width: 490px;
            width: max-content;
            display: flex;
            flex-direction: column;
            font-weight: 300;

            strong{
                font-size: 45px;
            }

            .saibaMais{
                margin: 0px;
                width: max-content;
                margin-top: 20px;
                font-size: 18px;
            }
        }

        .producoes{
            width: max-content;
            margin: 0;
        }

        .infografico{
            width: 45%;
            margin: 0;
        }

        .grafico{
            display: flex;
            justify-content: space-around;
            align-items: center;
            margin: 0px auto 60px;
            background-color: #D9D9D961;
            padding: 50px 0px;

            h2{
                font-size: 50px;
                font-weight: 300;
                width: max-content;
                margin-bottom: 20px;

                strong{
                    font-size: 50px;
                    font-weight: 700;
                }
            }

            ul{
                width: 90%;
            }

            li{
                font-size: 20px;
            }
        }

        .form{
            form{
                width: max-content;
            }

            flex-direction: row-reverse;
            justify-content: space-between;
            
            img{
                width: 100%;
            }

            input{
                width: 400px;
            }

            textarea{
                min-width: 400px;
                max-width: 400px;
            }
        }

        .divMapas{
            width: 90%;
            margin-right: 80px;

            img{
                max-width: none;
                margin: 10px 0px;
            }
        }

        .divParceiros{
            display: flex;
            padding: 20px 0px 20px 80px;

            img{
                height: 200px;
            }

            h2{
                width: 600px;
                text-align: left;
                margin-right: 50px;
                font-size: 50px;
                line-height: 45px;
                padding: 10px 0px;

                strong{
                    font-size: 50px;
                    font-weight: 600;
                }
            }
        }

        .producoes > .conhecaMais{
            margin: 20px 0px 0px;
            width: max-content;
            font-size: 18px;
        }
    }


    @media(min-width: 1440px){
        h1{
            font-size: 60px;
            max-width: 648px;

            strong{
                font-size: 60px;
            }
        }

        .vh50{
            display: flex;
        }
    }

    .saibaMais{
        .setaBotao{
            width: 30px ;
            height: 30px ;
            position: unset ;
            animation: 1s none ;
            opacity: 1 ;
        }
    }

    .grafico > li{
        font-size: 24px;
    }

    /* .setaBotao{
        width: 30px !important;
        height: 30px !important;
        position: unset !important;
        animation: 1s none !important;
        opacity: 1 !important;
    } */

`;