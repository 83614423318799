import styled from "styled-components";

export const PortifolioStyle = styled.div`
    background-color: #1D1D1B;
    position: relative;
    padding: 50px 0px 129px;
    margin-top: 30px;

    h2{
        font-size: 40px;
        font-weight: 300;
        width: max-content;
        color: #FFFFFF;
        padding: 0px 30px 0px 10%;

        strong{
            font-size: 40px;
            color: #FFFFFF;
            font-weight: 600;
        }
    }

    .lista{
        margin-top: 30px;
        padding-left: calc(10% + 18px);
        padding-top: 10px;
        padding-bottom: 100px;

        li{
            width: 300px;
            color: #747474;
            margin: 10px 0px;
        }

        .selecionado{
        color: white;
    }
    }

    .lista li {
    position: relative;
    padding-bottom: 5px;
}

    .lista .selecionado::after {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 2px;
        background-color: #ffffff; 
        animation: slide-left 8s linear ; 
    }

    img{
        width: 460px;
        height: 460px;
        border-radius: 16px;
        display: flex;
        position: absolute;
        top: 10%;
        right: 18%;
        object-fit: cover;
    }

    @keyframes slide-left {
    0% {
        width: 0%;
    }
    100% {
        width: 100%;
    }
    }

    img{
        opacity: 0; 
        transition: opacity 0.1s ease-in-out; 
    }

    .imagemSelecionada{
        display: flex;
        opacity: 1;
    }

    ol{
        display: none;
    }

    li{
        cursor: pointer;
    }

    .lista{
        display: none;
    }
    
    .relative{
        position: relative;
    }

    .listaMobile{
        margin-top: 30px;
        width: 100%;
        position: relative;
        height: 310px;

        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .imgEsquerda{
        position: absolute ; 
        left: 0px;
        top: 20%;
        opacity: 0.5;
        z-index: 1;
        width: 100px;
        height: 200px ;
        border-radius: 30px;
        object-fit: cover;
    }

    .leftPositivo{
        left: 30px;
    }

    .rightPositivo{
        right: 30px;
    }

    .seta-esquerda{
        color: white;
        font-size: 32px;
        position: absolute;
        left: 10%;
        top: 50%;
    }

    .seta-direita{
        color: white;
        font-size: 32px;
        position: absolute;
        right: 10%;
        top: 50%;
        z-index: 5;
    }

    .divImg{
        width: 300px;
        height: 300px;

        img{
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    .body{
        width: 250px;
        height: 250px;
    }

    .imgEsquerda{
        position: absolute; 
        left: 0px;
        top: 20% ;
        opacity: 0.5;
        z-index: 1;
        width: 100px ;
        height: 200px ;
        border-radius: 30px;
        object-fit: cover;
        animation: animacaoEsquerda 300ms normal forwards;
    }

    .imgDireita{
        position: absolute ;
        right: -50px ;
        top: 20% ;
        opacity: 0.5;
        z-index: 1;
        width: 150px ;
        height: 200px ;
        border-radius: 30px;
        object-fit: cover;
        animation: animacaoDireita 300ms normal forwards;
    }

    .imgPortifolio{
        width: 250px;
        height: 250px;
        top:0px;
        left: 0px;
        transition: none;
        opacity: 2;
        position: relative;
        z-index: 3;
        object-fit: cover;
        border-radius: 30px;
    }

    .animacaoDireitaCentro{
        animation: animacaoDireitaCentro 0.3s normal forwards;
    }

    .seta-direita-mobile{
        width: 17px;
        height: 21px;
        position: absolute;
        opacity: 1;
        z-index: 2;
        top: 45%;
        right: -50px;
    }

    .nome0,.nome1,.nome2,.nome3,.nome4,.nome5 {
        font-size: 20px;
        position: absolute;
        bottom: -20px;
        margin-bottom: 48px;
        color: #FFFFFF;
        animation: animacaoNome 2s normal forwards ;
    }

    @keyframes animacaoNome{
        0%{
            opacity: 0;
        }
        100%{
            opacity: 1;
            bottom: -70px;
        }
    }

    .seta-esquerda-mobile{
        width: 17px;
        height: 21px;
        position: absolute;
        opacity: 1;
        z-index: 2;
        top: 45%;
        left: -50px;
    }

    .animacaoSetaParaEsquerda{
        animation: animacaoSetaEsquerda 1s normal forwards;
    }

    .animacaoSetaParaDireita{
        animation: animacaoSetaDireita 1s normal forwards;
    }

    @keyframes animacaoSetaDireita {
        0%{
            right: 30px;
            opacity: 0.8;
        }
        100%{
            right: -50px;
            opacity: 1;
        }
    }

    @keyframes animacaoSetaEsquerda {
        0%{
            left: 30px;
            opacity: 0.8;
        }
        100%{
            left: -50px;
            opacity: 1;
        }
    }

    @keyframes animacaoDireitaCentro {
        0%{
            position: absolute;
            top: 20% ;
            left: 300px;
            opacity: 1;
            z-index: 3;
            width: 100px ;
            height: 200px ;
            border-radius: 30px;
            object-fit: cover;
        }
        100%{
            top: 0px ;
            right: 30px;
            opacity: 1;
            z-index: 5;
            border-radius: 30px;
            object-fit: cover;
        }
    }

    .animacaoEsquerdaCentro{
        animation: animacaoEsquerdaCentro 0.3s normal forwards;
    }

    @keyframes animacaoEsquerdaCentro {
        0%{
            position: absolute;
            top: 20% ;
            right: 300px;
            opacity: 1;
            z-index: 3;
            width: 100px ;
            height: 200px ;
            border-radius: 30px;
            object-fit: cover;
            opacity: 0.5;
        }
        100%{
            top: 0px ;
            left: 0px;
            opacity: 1;
            z-index: 5;
            border-radius: 30px;
            object-fit: cover;
            opacity: 1;
        }
    }
    

    @keyframes animacaoPortifolio {
        0% {
            opacity: 0;
            transform: scale(0.5);
        }
        100% {
            opacity: 1;
            transform: scale(1);
        }
    }

    @keyframes animacaoEsquerda {
        0%{
            width: 100px;
            height: 60%;
            top: 20%;
            left: 0px;
            transition: none;
            opacity: 1;
            z-index: 2;
        }
        100%{
            top: 16% ;
            left: -50px;
            opacity: 0.5;
            z-index: 1;
            width: 100px ;
            height: 70% ;
            border-radius: 30px;
            object-fit: cover;
        }
    }

    @keyframes animacaoDireita {
        0%{
            width: 100px;
            height: 50%;
            top: 20%;
            right: 0px;
            transition: none;
            opacity: 1;
            z-index: 2;
        }
        100%{
            top: 16% ;
            right: -50px;
            opacity: 0.5;
            z-index: 1;
            width: 100px;
            height: 70%;
            border-radius: 30px;
            object-fit: cover;
        }
    }

    .saibaMais{
        position: absolute;
        bottom: -100px;
    }

    .conhecaNossosCases{
        display: flex;
        margin: 8px 0px 0px -15px;
        border: none;
        background-color: rgb(216, 16, 32);
        color: white;
        border-radius: 8px;
        font-size: 18px;
        padding: 5px 12px 7px 17px;
        -webkit-box-align: center;
        align-items: center;
        width: max-content;

        img{
            width: 30px;
            height: 30px;
            opacity: 1;
            margin-top: 3px;
            margin-left: 10px;
        }
    }

    @media(min-width: 600px){
        .body{
            width: 350px;
            height: 350px;
        }

        .imgPortifolio{
            width: 350px;
            height: 350px;
        }

        .listaMobile{
            height: 400px;
        }
    }


    @media(min-width: 1204px){
        .lista{
            display: flex;
        
        li{
            font-size: 26px;
            margin-left: 10px;
        }
        }

        h2{
            font-size: 50px;

            strong{
                font-size: 50px;
            }
        }



        .imgPortifolio{
            width: 400px;
            height: 400px;
        }

        ol{
            display: flex;
            flex-direction: column;
        }
        
        .listaMobile{
            display: none
        }

        .body{
        width: 400px;
        height: 400px;
        }
    }
` 