import styled from 'styled-components';

export const ModalHeaderNavContainer = styled.div`
    width: 80%;
    height: 80%;
    border-radius: 36px 0px 0px 36px;

    position: fixed;
    top: 0;
    right: 0;

    background-color: #1D1D1B;
    opacity: 0.99;
    animation: normal 0.5s forwards slideIn;

    .modal-show {
        animation-name: slideIn;
    }

    .modal-hide {
        animation-name: slideOut;
    }

    a{
        font-size: 16px;
    }


    @keyframes slideIn {
        from {
            transform: translateX(100%);
        }
        to {
            transform: translateX(0);
        }
        }

        /* Animação de saída do modal */
    @keyframes slideOut {
        from {
            transform: translateX(0);
        }
        to {
            transform: translateX(-100%);
        }
        }


    .close{
        position: absolute;
        top: 20px;
        right: 10px;
        cursor: pointer;
    }
`;

export const HeaderContainer = styled.div`
    width: 100%;
    height: 70px;
    padding: 0 5%;

    display: flex;
    justify-content: space-between;
    align-items: center;

    position: fixed;
    top: 0;

    > .sit-svg {
        height: 80%;
        cursor: pointer;
        > div {
            height: 100%;
            > svg {
                height: 100%;
            }
        }
    }

    > .menu-container {
        display: flex;
        align-items: center;
        column-gap: 20px;
        height: 100%;
        cursor: pointer;
        margin-top: 20px;

        > .fechar-svg {
            height: 50%;
            > div {
                height: 100%;
                > svg {
                    height: 100%;
                }
            }
        }
    }
    
    @media screen and (max-width: 768px) {
        height: 40px;

        > .menu-container {
            column-gap: 10px;

            > p {
                font-size: 1.5em;
            }
        }
    }
`;

export const BodyContainer = styled.div`
    display: flex;
    margin-top: 100px;
    height: 100%;

    > nav {
        display: flex;
        flex-direction: column;
        width: max-content;
        margin-left: 20px;

        > a,
        > a:link,
        > a:active,
        > a:visited {
            text-align: left;
            font-size: 40px;
            font-weight: 100;
            text-decoration: none;
            padding-left: 30px;
            color: #FFFFFF;
            margin-bottom: 20px;
            margin-left: 40px;
        }
    
        .selecionado {
            font-weight: 400;
            border-left: 6px solid #d51b17;
        }
    }
`;