import Router from './routes';
import ReactGA from 'react-ga';
import {useEffect} from 'react';
import { ToastContainer } from 'react-toastify';
ReactGA.initialize('G-QZ95643DF0')

const App = () => {
  function Analytics(){
    ReactGA.pageview('Pagina inicial')
  }

  useEffect(()=>{
    Analytics()
  },[])
  return (
    <>
      <Router />
      <ToastContainer autoClose={4000}/>
    </>
  );
}

export default App;
