import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
    * {
        margin: 0;
        padding: 0;
        box-sizing: border-box;
        font-size: 18px;
        font-family: 'Open Sans', sans-serif;
        scroll-behavior: smooth;
        color: #333;
    }

    button,
    a {
        cursor: pointer;
    }

    @media screen and (max-width: 768px) {
        * {
            font-size: 13px;
        }
    }

    .flex{
        display: flex;
    }
`

export default GlobalStyle;