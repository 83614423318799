import { FooterContainer } from './styles';
import RedesSociais from '../../assets/redesSociais.svg';
import RodaPe from '../../assets/rodaPe.svg';
import YoutubeFooter from "../../assets/youtubeFooter.svg"
import InstagramFooter from "../../assets/instagramFooter.svg"
import LinkedinFooter from "../../assets/linkedinFooter.svg"

const Footer = () => {
    return (
        <FooterContainer>
            <div className='body'>
                <div className='column'>
                    <div>
                        <a href='https://www.youtube.com/@sittrade' target='_blank'>
                            <img className='redeSocial' src={YoutubeFooter} alt='redes-sociais'/>
                        </a>
                        <a href='https://www.instagram.com/sittrademkt/' target='_blank'>
                            <img className='redeSocial' src={InstagramFooter} alt='redes-sociais'/>
                        </a>
                        <a href='https://www.linkedin.com/company/sittrade/?originalSubdomain=br' target='_blank'>
                            <img className='redeSocial' src={LinkedinFooter} alt='redes-sociais'/>
                        </a>
                    </div>
                    <span>Trabalhe conosco</span>
                </div> 
                <img className='rodaPe' src={RodaPe} alt='Roda Pé'/>
            </div>
            <div className='fim'>
                <span>SIT TRADE - TODOS OS DIREITOS RESERVADOS 2024</span>
            </div>
        </FooterContainer>
    );
};

export default Footer;