import Footer from "../../components/Footer"
import { Form } from "../../components/Form"
import Header from "../../components/Header"
import { Sobre } from "../../components/Sobre"
import { SobrePageStyle } from "./styles"

export const SobrePage = () => {
    return(
        <SobrePageStyle>
            <Header/>
            <Sobre/>
            <Form/>
            <Footer/>
        </SobrePageStyle>
    )
}