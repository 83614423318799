import { CasesContainer } from './styles';
import { useState } from "react";
import { useRef } from "react";
import { useEffect } from "react";
import { Form } from "../Form";

const Cases = () => {
    const [isPlayingOxxo, setIsPlayingOxxo] = useState(false);
    const [isPlayingLinea, setIsPlayingLinea] = useState(false);
    const [isPlayingWhiskas, setIsPlayingWhiskas] = useState(false);
    const [loadingWhiskas, setLoadingWhiskas] = useState(true);
    const videoRefOxxo = useRef(null); 
    const videoRefLinea = useRef(null);
    const videoRefWhiskas = useRef(null);
  
    const handlePlayPauseOxxo = () => {
      if (isPlayingOxxo) {
        setIsPlayingOxxo(false);
      } else {
        setIsPlayingOxxo(true);
      }
    };

    const handlePlayPauseLinea = () => {
        if (isPlayingLinea) {
            setIsPlayingLinea(false);
        } else {
            setIsPlayingLinea(true);
        }
    }

    const handlePlayPauseWhiskas = () => {
        if (isPlayingWhiskas) {
            setIsPlayingWhiskas(false);
        } else {
            setIsPlayingWhiskas(true);
        }
    }


    useEffect(() => {
        if (isPlayingOxxo) {
          videoRefOxxo.current.play();
        } else {
          videoRefOxxo.current.pause();
        }
    }, [videoRefOxxo])

    useEffect(() => {
        if (isPlayingLinea) {
          videoRefLinea.current.play();
        } else {
          videoRefLinea.current.pause();
        }
    }
    , [videoRefLinea])

    useEffect(() => {
        if(videoRefWhiskas){           
            if (isPlayingWhiskas) {
                videoRefWhiskas.current.play();
            } else {
                videoRefWhiskas.current.pause();
            }
        }
    }
    , [videoRefWhiskas])

    return (
        <CasesContainer>
            <div className="titulo">
                <h1>Alguns
                        <strong>
                            {"projetos incríveis "}
                        </strong>
                        que saíram da nossa cartola.         
                </h1>
                    <img src={"https://istockblobs.blob.core.windows.net/sitesittrade/cartola.svg"} alt="cartola"/>
            </div>
            <div className="divMikes">
                <img src={"https://istockblobs.blob.core.windows.net/sitesittrade/mikes.webp"} className="mikes" alt="mikes"/>
                <span className="tag">
                    Copa do Mundo
                    <strong>
                        {" - Ambev"}
                    </strong>
                </span>
            </div>
            <section className="textos">
                <h3>
                    <strong>
                        {" A Copa do Mundo é um momento extremamente importante para a Ambev criar conexão com seu público"}
                    </strong>
                    , já que Brahma é a patrocinadora da torcida brasileira e Guaraná, da seleção do Brasil. Na Copa de 2022, 
                    a companhia trouxe ainda sua mais recente marca: Mike’s, para marcar presença entre os jogos da nossa seleção.
                </h3>
                <h3>
                    <strong>
                        {" A SIT entrou de cabeça nas campanhas "}
                    </strong>
                    das três marcas, que acabaram sendo um record de jobs tanto de quantidades quanto de prazos aqui na empresa. 
                    Produzimos materiais leves e softs para as três frentes e desenvolvemos alguns projetos especiais,
                    como ativação em eventos e uma mega execução tailor made para um PDV da região Sul. no hipermercado Rio Verde.
                </h3>
                <h3>
                    Totalizamos
                    <strong>
                        {` 2.2 milhões de materiais gráficos, 73 mil materiais de comunicação visual, 1620 ilhas e racks, 144 positivações,
                         22 ativações, 2 tailors mades, 1 espaço proprietário de Mike’s no evento BudX e 1 mega execução no hipermercado Rio Verde.`}
                    </strong>
                </h3>
                <h3>
                    Nessa Copa nossa Seleção não levou a taça, mas nossa equipe teve uma excelente atuação em campo, levando a
                    <strong>
                        {" distinção do nome Sit para os PDVs e eventos!"}
                    </strong>
                </h3>
            </section>
                <div className="divMikes">
                        <img src={"https://istockblobs.blob.core.windows.net/sitesittrade/Fotos 1.webp"} className="mikes" alt="mikes"/>
                    <span className="tag">
                        Beto Carreiro e
                        <strong>
                            {" Guaraná"}
                        </strong>
                    </span>
                </div>
            <section className="textos">
                <h3>
                    Em 2021, a Ambev e o Parque Beto Carrero fecharam um acordo comercial e a
                    <strong>
                        {"  SIT foi chamada para desenvolver alguns jobs "}
                    </strong>
                    de tailor made de Guaraná, Colorado e Patagônia para inserir as marcas na 
                    experiência de parque do público.
                </h3>
                <h3>
                    agregando um local de valor para o público do parque.
                    Para Colorado, na área da Boca do Leão, 
                    <strong>
                        {" desenvolvemos um espaço para posicionar a marca "}
                    </strong>
                    e mostrar os SKUs e os atributos institucionais com um mobiliário próprio.
                </h3>
                <h3>
                    Para Guaraná, uma marca brasileira de grande aceitação e afeto por parte do público, 
                    <strong>
                        {" projetamos um espaço para descanso "}
                    </strong>
                    com áreas de photo opportunity para carregar a bateria do celular e vending machines, 
                </h3>
                <h3 className="">
                    Por fim, perto da área Germânica do parque, 
                    <strong>
                        montamos o tailor made de Patagônia,
                    </strong>
                    trazendo seu posicionamento mais rústico com madeiras escuras mesclada com iluminação.
                </h3>
            </section>
            <section>
                <div className="divMikes">
                    <img src={"https://istockblobs.blob.core.windows.net/sitesittrade/dante.webp"} className="mikes" alt="mikes"/>
                    <span className="tag">
                        Bodega
                        <strong>
                            {" Dante Robino"}
                        </strong>
                    </span>
                </div>
                <section className="textos">

                <h3>
                    A Ambev iniciou sua venda de vinhos no Brasil com a aquisição da bodega argentina Dante Robino de Mendoza,
                    <strong>
                        {"  e a SIT foi a agência escolhida "}
                    </strong>
                    para desenvolver as peças off trade dessa estreia.
                </h3>
                <h3>
                    Sabemos que a área de vinho nos mercados é pequena e bem disputada, então desenvolvemos um enxoval com ilha 
                    e rack na medida para esses espaços, além de materiais leves e caixas para presente.
                </h3>
                <h3>
                    Os materiais precisavam mostrar a tradição do vinho e seu reconhecimento centenário no mercado argentino, 
                    trazendo a identidade da marca nas linhas Novecento, Dante Robino, Legado e Gran Dante. 
                </h3>
                <h3>
                    <strong>
                        Já para degustação e eventos
                    </strong>
                    , idealizamos alguns modelos de carrinhos que variam em tamanho para atender às diferentes necessidades das ocasiões.
                </h3>
                </section>
            </section>
            <section>
                <div className="divMikes">
                    <img src={"https://istockblobs.blob.core.windows.net/sitesittrade/big-john.webp"} className="mikes" alt="mikes"/>
                    <span className="tag">
                        Projeto 
                        <strong>
                            {"-  Big John"}
                        </strong>
                    </span>
                </div>
                <section className="textos">
                <h3>
                    Neste job, o cliente
                    <strong>
                        {" nos acionou para desenvolver algo diferente "}
                    </strong>
                    para a marca, onde tivemos a oportunidade de participar da fase inicial do seu departamento de trade.
                </h3>
                <h3>
                    <strong>
                        {" Desenvolvemos então o projeto criativo, "}
                    </strong>
                        onde trouxemos a essência da marca em forma de ilha preparada para degustações e positivação dos produtos, 
                        trabalhando experiência de marca com os seus consumidores.
                </h3>
                <h3 className="marginNegativa20">
                    A cervejaria tinha um espaço em um mercado localizado na tríplice fronteira entre Argentina, 
                    Santa Catarina e Paraná.
                </h3>
                </section>
            </section>
            <section>
                <div className="divMikes">
                    <img src={"https://istockblobs.blob.core.windows.net/sitesittrade/swift.webp"} className="mikes" alt="mikes"/>
                    <span className="tag">
                        Enxoval 
                        <strong>
                            {" -  Swift"}
                        </strong>
                    </span>
                </div>
                <section className="textos">

                <h3>
                    <strong>
                        {" A parceria SIT & SWIFT atua mensalmente, "}
                    </strong>
                    desde a produção ao manuseio e logística de todos os enxovais das campanhas de MPDV da marca a nível nacional.
                </h3>
                <h3>
                    onde acompanhamos as produções locais e controlamos pedidos e cadastro, através do Portal Swift.
                </h3>
                <h3>
                    Além disso, temos o controle de todo o fluxo de reposição das peças de comunicação de promoção para os mobiliários de 
                    loja, 
                </h3>
                <h3>
                    Dessa forma,
                    <strong>
                        {` nós otimizamos toda a cadeia de abastecimento do cliente, 
                        minimizando prazos e mitigando riscos de falhas de reposição e conteúdo nas lojas!`}
                    </strong>
                </h3>
                </section>
            </section>
            <div className="relative" onClick={() => handlePlayPauseOxxo()}>
                <video  ref={videoRefOxxo} controls >
                    <source  src={"https://istockblobs.blob.core.windows.net/sitesittrade/oxxo.mp4"} type="video/mp4" />
                </video>
                {!isPlayingOxxo && 
                    <div onClick={() => videoRefOxxo.current.play()}>
                        <span className="play">▶</span>
                        <h6>Clique para dar play!</h6>
                    </div>
                }
            </div>
            <section className="textos">
                <h3>
                    Todos os meses a Oxxo muda a comunicação visual de suas lojas no estado de São Paulo. 
                    O nome desse movimento mensal é Promoxxo, um ciclo de ofertas que atua como principal
                    alavanca comercial para 
                    <strong>
                        {" gerar fluxo e desenvolver categorias de produtos."}
                    </strong>
                </h3>
                <h3>
                    Além de atuar como gráfica e logística, o cliente conta com nossa expertise para contribuir na 
                    adaptação de formatos; acompanhamento real time das entregas através da nossa plataforma gerencial; 
                    tratativas de ocorrências on time; gestão de aberturas para que nenhuma loja fique sem os enxovais e 
                    estudo de processos para melhoria de SLA, onde roteirizamos as saídas para as praças junto ao manuseio, 
                    garantindo maior agilidade nas etapas e processos.
                </h3>
                <h3 className="marginNegativa">
                    A
                    <strong>
                        {"  SIT é a atual fornecedora parceira homologada da marca "}
                    </strong>
                    {` para a produção e distribuição dos MDPVs de todas as lojas, 
                    que hoje somam cerca de 570 unidades, espalhadas entre a capital, o litoral e o interior `}
                </h3>
            </section>
            <div className="relative" onClick={() => handlePlayPauseWhiskas()}>
                <video ref={videoRefWhiskas} controls className="whiskas-video" onCanPlay={() => setLoadingWhiskas(false)} >
                    <source  src={"https://istockblobs.blob.core.windows.net/sitesittrade/whiskas.mp4"} type="video/mp4"/>
                </video>
                {!isPlayingWhiskas && !loadingWhiskas && 
                    <div onClick={() => videoRefWhiskas.current.play()}>
                        <span className="play">▶</span>
                        <h6>Clique para dar play!</h6>
                    </div>
                }
            </div>
            <section className="textos padding">
                <h3>
                    Ao final de 2023, recebemos de Whiskas 
                    <strong>{" a missão de construir uma ação para o Natal "}</strong>
                    que transmitisse a importância dos pets e do espaço que eles ocupam na nossa vida e na nossa sociedade.
                </h3>
                <h3>
                    Suas unhas enquanto brincavam  com os petiscos que eram depositados através da chaminé!
                    Para fechar essa ação especial, ainda fomos convocados para garantir o Natal dos gatinhos de um cat café. 
                </h3>
                <h3>
                    Para presentear as famílias, nosso time criou então uma caixa transporte que virava uma casinha para gatos. 
                    Dentro da caixa vinham sachês, cartão de natal, carta personalizada e um arranhador em formato de árvore.
                    O fundo da árvore também era um arranhador para os gatinhos afiarem suas unhas enquanto brincavam  com os petiscos 
                    que eram depositados através da chaminé!
                </h3>
                <h3 >
                    Sabendo que os felpudos amam brincar, subir e descer por todos os lados e que uma árvore convencional de Natal 
                    pode não combinar muito com esse cenário, propusemos e desenvolvemos uma árvore de madeira especialmente para 
                    trazer mais afeto e acolhimento aos gatinhos disponíveis para adoção, garantindo o clima natalino enquanto eles 
                    esperavam por uma família!     
                </h3>
            </section>
            <div className="relative" onClick={() => handlePlayPauseLinea()}>
                <video ref={videoRefLinea} controls >
                    <source  src={"https://istockblobs.blob.core.windows.net/sitesittrade/linea.mp4"} type="video/mp4" />
                </video>
                {!isPlayingLinea && 
                    <div onClick={() => videoRefLinea.current.play()}>
                        <span className="play">▶</span>
                        <h6>Clique para dar play!</h6>
                    </div>
                }
            </div>
            <section className="textos">
                <h3>
                    Para a convenção anual de Linea, eles 
                    <strong>
                        {" precisavam de um evento que comportasse as apresentações da empresa "}
                    </strong>
                    e garantisse um after cheio de música, descontração e divertimento.
                </h3>
                <h3>
                    Na parte interna do local, o projeto contava com uma plenária integral, 
                    com um painel de led de 10x3 metros, sistema de som e iluminação.
                </h3>
                <h3>
                    Fomos acionados 12 dias antes do evento e ficamos responsáveis por 
                    <strong>
                        {" todo o planejamento, estrutura e montagem, "}
                    </strong>
                    feitos em apenas 10 dias.
                </h3>
                <h3>
                    Os materiais de PDV apresentados na convenção e elogiados pelo presidente da marca também 
                    <strong>
                        {` foram todos SIT: displays de chão e balcão, frame de gôndola, uniformes, 
                        balcões e expositores compuseram o enxoval. `}
                    </strong>
                    Fomos com uma equipe de 10 pessoas para conduzir os 3 dias desse evento que ficou marcado para o time da marca!
                </h3>
                <h3 className="marginNegativa">
                    Na parte externa, montamos um palco para a apresentação do grupo de pagode contratado e um dispositivo de fotos 360º,
                    <strong>
                        {" garantindo uma festa completa. "}
                    </strong>
                </h3>
            </section>
            <Form/>
        </CasesContainer>
    );
};

export default Cases;