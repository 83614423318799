import styled from "styled-components";

export const TermosUsoStyle = styled.div`
    width: 90%;
    display: flex;
    flex-direction: column;
    margin: 0 auto;

    h3{
        font-size: 12px;
        font-weight: 500;
        margin-top: 18px;
    }

    h2{
        font-size: 12px;
        font-weight: 500;
    }

    button{
            padding: 6px;
            background-color: #D81020;
            width: max-content;
            display: flex;
            margin: 40px 0px 60px;
            font-size: 16px;
            border: none;
            border-radius: 6px;
            color: white;
        }
`