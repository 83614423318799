import Header from "../../components/Header"
import { Solucoes } from "../../components/Solucoes"
import { NossasSolucoesPageStyle } from "./style"
import Footer from "../../components/Footer"

export const NossasSolucoesPage = () => {
    return (
        <NossasSolucoesPageStyle>
            <Header />
            <Solucoes/>
            <Footer/>
        </NossasSolucoesPageStyle>
        )
}