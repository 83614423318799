import { FormStyle } from "./styles"
import Loc from "../../assets/loc.svg";
import { useForm } from "react-hook-form";
import LazyLoad from "react-lazyload";
import { useNavigate } from "react-router-dom";
import api from "../../services/api";
import { yupResolver } from "@hookform/resolvers/yup";
import { toast } from "react-toastify";
import * as Yup from 'yup';
import { useEffect } from "react";
import Cartola from "../../assets/cartola.svg";
import setaBotao from "../../assets/setaBotao.svg";

export const Form = () => {
    const schema = Yup.object().shape({
        nome: Yup.string().required('O nome é obrigatório'),
        email: Yup.string().email('Digite um e-mail válido').required('O e-mail é obrigatório'),
        telefone: Yup.string().required('Telefone é obrigatório'),
        assunto: Yup.string().required('O assunto é obrigatória'),
        mensagem: Yup.string().required('Mensagem é obrigatória').max(500, 'Limite de 500 caracteres excedido'),
    });

    const { register, handleSubmit, formState: { errors }, reset, getValues } = useForm({
        resolver: yupResolver(schema)
    });
    const navigate = useNavigate();

    useEffect(() => {
        
        reset({
            checkbox: localStorage.getItem("termos") === "aceito" ? true : false
        })
    }, [])

    const enviarForm = async (data) => {
        const { checkbox } = data;

        if (!checkbox) {
            toast.error("É necessário aceitar os termos de uso e política de privacidade");
            return;
        }

        toast.success("E-mail enviado com sucesso 🥳")
        await api.post("/emailSiteSit", 
            data
        ).then((response) => {
            reset({
                nome: "",
                email: "",
                telefone: "",
                assunto: "",
                mensagem: "",
                checkbox: false
            })
        }).catch((error) => {
            toast.error("Erro ao enviar e-mail, tente novamente mais tarde")
        })
    }

    return(
        <FormStyle id="form ">
            <div className='conversar'>
                <h4>Vamos</h4>
                <h3>conversar?</h3>
            </div>
            <div className='form'>
                <form onSubmit={handleSubmit(enviarForm)}>
                    <div className="relative">
                        <input placeholder='Nome' {...register("nome")}/>
                        {errors.nome && <p>{errors.nome.message}</p>}
                    </div>
                    <div className="relative">
                        <input placeholder='E-mail' {...register("email")}/>
                        {errors.email && <p>{errors.email.message}</p>}
                    </div>
                    <div className="relative">
                        <input placeholder='Telefone' {...register("telefone")}/>
                        {errors.telefone && <p>{errors.telefone.message}</p>}
                    </div>
                    <div className="relative">
                        <select {...register("assunto")}>
                            <option value="" disabled>Selecione o assunto</option>
                            <option>Quero ser cliente</option>
                            <option>Quero fazer parte do time</option>
                            <option>Quero ser um fornecedor</option>
                            <option>Outros</option>
                        </select>
                        {errors.assunto && <p>{errors.assunto.message}</p>}
                    </div>
                    <div className="relative">
                        <textarea placeholder='Mensagem' {...register("mensagem")}/>
                        {errors.mensagem && <p>{errors.mensagem.message}</p>}
                    </div>
                    <div className='divCheckox'>
                        <input className='checkbox' type='checkbox' {...register("checkbox")} onClick={() => {
                            if(getValues("checkbox")){
                                localStorage.removeItem("termos")
                            }
                        }}/>
                        <label>Concordo com os <strong onClick={() => navigate("/termosUso")}>Termos de Uso</strong> e <strong onClick={() => navigate("/termosUso")}>Política de Privacidade</strong></label>
                    </div>
                    <button>
                        enviar
                        <img src={setaBotao} className='setaBotao'/>
                    </button>
                </form>
                <div className='divMapas'>
                    <LazyLoad>
                        <a target='_blank' href='https://www.google.com.br/maps/place/SIT+-+Soluções+Inteligentes+para+Trade+Marketing/@-23.6321583,-46.7375898,17z/data=!3m1!4b1!4m6!3m5!1s0x94ce51009a473a47:0xd14981603c473bfb!8m2!3d-23.6321632!4d-46.7350095!16s%2Fg%2F11h79l1kf6?entry=ttu&g_ep=EgoyMDI0MTAwMS4wIKXMDSoASAFQAw%3D%3D'>
                            <img src={Loc} className='loc' alt="localização"/>
                        </a>
                    </LazyLoad>
                    <span className="spanMapa"> Rua Jandiatuba, 506, B2, sala 622</span> 
                    <span className="spanMapa"> 05716-750 - Vila Andrade - São Paulo - SP</span>
                    <span className='email'>contato@sittrade.com.br</span>
                </div>
            </div>
            <img src={Cartola} className="cartola"/>
        </FormStyle>
    )
}