import { HeaderContainer } from './styles';
import SittradeLogo from '../../assets/logo-sit-branco.png';
import Menu from '../../assets/linhas.png';
import { useState } from 'react';
import ModalHeaderNav from '../ModalHeaderNav';
import { useLocation, useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { useEffect } from 'react';

const Header = () => {
    const [navModalOpened, setNavModalOpened] = useState(false);
    const navigate = useNavigate();
    const url = useLocation().pathname;
    return (
        <HeaderContainer>
            <img 
                className='sit-svg'
                onClick={() => navigate('/')} 
                src={SittradeLogo} alt='logo'
            />
            <div className='menu-container' >
                <div className='link'>
                    <Link to={"/home"} className={url === "/" ? "selecionado" : ""}>Home</Link>
                </div>
                <div className='link'>
                    <Link to={"/sobre"} className={url === "/sobre" ? "selecionado" : ""}>Sobre a Sit</Link>
                </div>
                <div className='link'>
                    <Link to={"/solucoes"} className={url === "/solucoes" ? "selecionado" : ""}>Soluções</Link>
                </div>
                <div className='link'>
                    <Link to={"/cases"} className={url === "/cases" ? "selecionado" : ""}>Cases</Link>
                </div>
                <button onClick={() => {
                    const elementoClicado = document.getElementsByClassName('form')[0];
                    if (elementoClicado){
                        elementoClicado.scrollIntoView({
                            behavior: "smooth",
                            block: "center",
                        })
                        setTimeout(() => {
                            elementoClicado.scrollIntoView({
                                behavior: "smooth",
                                block: "center",
                            })
                        }, 1000)
                    }
                }}>
                    Vamos conversar?
                </button>
            </div>
            <img 
                onClick={() => setNavModalOpened(true)}
                className='menu-svg'
                src={Menu} 
                alt='menu'
            />
            {navModalOpened && <ModalHeaderNav setCloseModal={setNavModalOpened}/>}
        </HeaderContainer>
    )
}

export default Header;