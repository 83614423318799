import styled from "styled-components";

export const SobreStyle = styled.div`
    margin-top: 70px;
    overflow-x: hidden;

    h1{
        padding: 35px 0px;
        display: flex;
        margin: 0 auto;
        font-size: 25px;
        font-weight: 300;
        color: #1D1D1B;
        justify-content: center;
        position: relative;

        strong{
            font-size: 25px;
            font-weight: 700;
            margin-left: 8px;
        }

        img{
            position: absolute;
            right: -12px;
            top: 10px;
            width: 80px;
            height: 80px;
        }
    }

    .cartola{
        display: none;
    }

    .h1Grande{
        display: none;
    }

    .todos{
        width: 100%;
    }

    .descricao{
        font-size: 16px;
        font-weight: 300;

        width: 90%;
        margin: 0 auto;
        margin-top: 20px;
        line-height: 23px;

        strong{
            font-size: 16px;
            font-weight: medium;
        }
    }

    h3{
        font-size: 25px;
        font-weight: 300;
        width: max-content;
        color: #1D1D1B;
        padding: 50px 30px 0px 20px;
        display: flex;
        flex-direction: column;

        strong{
            font-size: 25px;
            color: #1D1D1B;
            font-weight: 500;
        }
    }

    h4{
        font-size: 16px;
        font-weight: 300;
        width: 90%;
        margin: 0 auto;
        line-height: 23px;
        text-align: center;

        strong{
            font-size: 16px;
            font-weight: medium;
        }
    }

    .icone-vermelha{
        display: flex;
        margin: 0 auto;
    }

    .seta{
        display: flex;
        margin: 10px auto;
        width: 40px;
        height: 40px;
    }

    .fluxo{
        margin: 50px 0px;
        position: relative;
    }

    .marginTop20{
        margin-top: 20px;
    }

    .marginTop50{
        margin-top: 50px;
        text-align: center;
    }

    .manifesto{
        position: relative;
        background-color: #D81020;
        width: 100%;
        padding: 50px ;

        h2{
            color: #FFFFFF;
            font-size: 24px;
            font-weight: 400;
            margin-bottom: 30px;
            width: 80%;
        }

        h5{
            color: #FFFFFF;
            font-size: 16px;
            font-weight: 300;
            margin: 0 auto;
            line-height: 23px;
            margin-bottom: 20px;
        }

        strong{
            color: #FFFFFF;
            font-size: 16px;
            font-weight: 500;
        }
    }

    .estrelaCinza{
        position: absolute;
        right: 0px;
        top: 100px;
    }

    .setaCimaBaixo{
        display: none;
        height: 115px;
        margin: 10px auto;
    }

    .virgula{
        display: none;
    }

    .estrelaEsquerda{
        display: none;
    }

    h6{
        color: #FFFFFF;
        font-size: 16px;
        font-weight: 300;
        margin: 0 auto;
        line-height: 23px;
        margin: 0px 0px 6px;
    }

    .manifesto .marginTop10{
        margin-top: 10px;
    }
    
    .semMargin{
        margin: 0px;
    }

    @media(min-width: 1024px){
        .virgula{
            top: 0;
            left: 0;
            display: flex;
            position: absolute;
            width: 280px;
        }

        .todos{
            width: 70%;
        }

        .width80{
            width: 80%;
            display: flex;
            margin: 0 auto;
            flex-direction: column;
        }

        .setaCimaBaixo{
            display: flex;
        }

        .seta{
            display: none;
        }

        .estrelaEsquerda{
            display: flex;
            position: absolute;
            top: 100px;
            width: 350px;
        }

        .estrelaCinza{
            display: none;
        }
        
        .setaCimaBaixo{
            display: flex;
        }

        h1{
            width: 300px;
            font-size: 40px;
            display: none;
            margin: 0px;

            strong{
                font-size: 40px;
            }
        }

        .descricao{
            font-size: 16px;
            margin: 0px;
        }

        .margin-top{
         margin-top:20px
        }


        .top{
            display: flex;
        }

        .h1Grande{
            display: block;
        }

        .column{
            position: relative;
            display: flex;
            flex-direction: column;
            margin-left: 60px;
            text-align: left;
            width: 40%;
            height: max-content;

            img{
                display: flex;
                position: absolute;
                right: 0px;
                top: 0px;
                width: 240px;
                height: 240px;
            }
        }

        .seta{
            display: none;
        }
    
        .parte{
            display: flex;
            justify-content: center;
            margin: 0 auto;
            width: 100%;
            max-width: 500px;

            h4{
                text-align: left;
                margin-left: 20px;
            }
        }

        .marginTop20{
            max-width: 600px;
            text-align: left;
        }

        .marginTop50{
            max-width: 600px;
            text-align: left;
        }

        h3{
            margin-left: 100px;
            font-size: 45px;

            strong{
                font-size: 45px;
            }
        }

        .manifesto{
            padding: 50px 0px;

            h5{
                max-width: 500px;
                margin: 0px 0px 20px;
            }
        }

    .manifesto .semMargin{
        margin: 0px;
    }
    }

    @media(min-width: 1160px){
        h1{
            width: 200px;
        }
    }

    @media(min-width: 1280px){
        .descricao{
            font-size: 20px;
            line-height: 1.2;
            max-width: 360px;

            strong{
                font-size: 20px;
            }
        }

        .parte{
            max-width: 700px;

            h4{
                font-size: 20px;
                line-height: 1.3;

                strong{
                    font-size: 20px;
                }
            }
        }

        .marginTop50{
            max-width: 720px;
            font-size: 20px;

            strong{
                font-size: 20px;
            }
        }

        .marginTop20{
            max-width: 720px;
            font-size: 20px;

            strong{
                font-size: 20px;
            }
        }
    }
`