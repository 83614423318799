import { BodyContainer, HeaderContainer, ModalHeaderNavContainer } from './styles';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import SittradeLogo from '../../assets/logo-sit.svg';
import InstagramBranco from '../../assets/instagram-branco.svg';
import LinkedinBranco from '../../assets/linkedin-branco.svg';
import Fechar from '../../assets/fechar.svg';
import TrioCirculoFundoPreto from '../../assets/trio-circulo-fundopreto.svg';
import { ReactSVG } from 'react-svg';

const ModalHeaderNav = ({setCloseModal}) => {
    const url = useLocation().pathname;

    return (
        <ModalHeaderNavContainer className='slideIn'>
            <HeaderContainer>
                <div className='menu-container' onClick={() => setCloseModal(false)}>
                    <ReactSVG className='fechar-svg' src={Fechar} alt='fechar'/>
                </div>
            </HeaderContainer>
            <BodyContainer>
                <nav>
                    <Link to='/' className={url === "/" ? "selecionado" : ""} onClick={() => setCloseModal(false)}>Home</Link>
                    <a href='/sobre' className={url === "/sobre" ? "selecionado" : ""} onClick={() => setCloseModal(false)}>Sobre a SIT</a>
                    <Link to='/solucoes' className={url === "/solucoes" ? "selecionado" : ""} onClick={() => setCloseModal(false)}>Soluções</Link>
                    <a href='/cases' className={url === "/cases" ? "selecionado" : ""} onClick={() => setCloseModal(false)}>Nossos Cases</a>
                </nav>
            </BodyContainer>
        </ModalHeaderNavContainer>
    );
};

export default ModalHeaderNav;